import glbFile from "src/assets/common_img/3d-file.png";
import add from "src/assets/common_img/add.svg";
import add2 from "src/assets/common_img/add2.svg";
import iconarrdown from "src/assets/common_img/arrdown.png";
import cardBrand from "src/assets/common_img/card-brand.svg";
import close from "src/assets/common_img/close.svg";
import download from "src/assets/common_img/download.svg";
import icon1 from "src/assets/common_img/icon1.svg";
import icon10 from "src/assets/common_img/icon10.svg";
import icon11 from "src/assets/common_img/icon11.svg";
import icon2 from "src/assets/common_img/icon2.svg";
import icon3 from "src/assets/common_img/icon3.svg";
import icon4 from "src/assets/common_img/icon4.svg";
import icon5 from "src/assets/common_img/icon5.svg";
import icon6 from "src/assets/common_img/icon6.svg";
import icon7 from "src/assets/common_img/icon7.svg";
import icon8 from "src/assets/common_img/icon8.svg";
import icon9 from "src/assets/common_img/icon9.svg";
import mainLogo from "src/assets/common_img/liex-03.png";
import logoSide from "src/assets/common_img/liex-04.png";
import analysis1 from "src/assets/images/analysis/analysis1.svg";
import analysis2 from "src/assets/images/analysis/analysis2.svg";
import analysis4 from "src/assets/images/analysis/analysis4.svg";
import graph1 from "src/assets/images/analysis/graph1.png";
import graph2 from "src/assets/images/analysis/graph2.png";
import logo from "src/assets/images/logo.png";

import medicalImage from "src/assets/images/medical_management/img.png";
import note from "src/assets/images/planchange/note.svg";

import back from "src/assets/common_img/back.svg";
import boxActive from "src/assets/common_img/box-active.svg";
import boxInActive from "src/assets/common_img/box-inactive.svg";
import closePly from "src/assets/common_img/close_ply.svg";
import icocsv from "src/assets/common_img/csv2.png";
import download2 from "src/assets/common_img/download_2.svg";
import enlarge from "src/assets/common_img/enlarge.svg";
import fileManagement from "src/assets/common_img/file_management.svg";
import folderMove2 from "src/assets/common_img/folder_2.svg";
import folderMove from "src/assets/common_img/folder_move.svg";
import gallery from "src/assets/common_img/gallery.svg";
import getLink from "src/assets/common_img/get_link.svg";
import glass from "src/assets/common_img/glass.svg";
import hide from "src/assets/common_img/hide.svg";
import icoodf from "src/assets/common_img/ico-pdf.png";
import iconprofile from "src/assets/common_img/ico-profile.png";
import icosmoke from "src/assets/common_img/ico-smoke.png";
import iconuser from "src/assets/common_img/ico-user.png";
import loadmore from "src/assets/common_img/loadmore.svg";
import penRename from "src/assets/common_img/pen_rename.svg";
import ply from "src/assets/common_img/ply.svg";
import ply2 from "src/assets/common_img/ply2.svg";
import rotate from "src/assets/common_img/rotate.svg";
import scale from "src/assets/common_img/scale.svg";
import stl from "src/assets/common_img/stl.svg";
import translate from "src/assets/common_img/translate.svg";
import trashCan from "src/assets/common_img/trash_can.svg";
import upload from "src/assets/common_img/upload.svg";
import upload2 from "src/assets/common_img/upload_2.svg";
import noticeOperatorImage from "src/assets/images/notice_operator/img.png";
import noticeOperatorPDF from "src/assets/images/notice_operator/pdf.png";

import flipHorizontal from "src/assets/common_img/flip_horizontal.svg";
import flipVertical from "src/assets/common_img/flip_vertical.svg";
import rotateLeft from "src/assets/common_img/rotate_left.svg";
import rotateRight from "src/assets/common_img/rotate_right.svg";

import partnerComp1 from "src/assets/common_img/dscore.png";
import partnerComp2 from "src/assets/common_img/3shape.png";
import partnerComp3 from "src/assets/common_img/itero.png";
import partnerComp4 from "src/assets/common_img/dental_cloud.png";
import partnerComp5 from "src/assets/common_img/medit_link.png";

export const Assets = {
  logoSide,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  icon11,
  iconarrdown,
  download,
  download2,
  upload,
  upload2,
  noticeOperatorImage,
  noticeOperatorPDF,
  mainLogo,
  logo,
  note,
  cardBrand,
  medicalImage,
  glbFile,
  analysis1,
  analysis2,
  analysis4,
  graph1,
  graph2,
  close,
  add,
  add2,
  ply,
  ply2,
  stl,
  closePly,
  back,
  enlarge,
  hide,
  boxActive,
  boxInActive,
  loadmore,
  iconuser,
  iconprofile,
  icosmoke,
  icoodf,
  icocsv,
  translate,
  rotate,
  scale,
  fileManagement,
  glass,
  gallery,
  folderMove,
  folderMove2,
  penRename,
  trashCan,
  getLink,
  rotateRight,
  rotateLeft,
  flipVertical,
  flipHorizontal,
  partnerComp1,
  partnerComp2,
  partnerComp3,
  partnerComp4,
  partnerComp5,
};
